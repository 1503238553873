<!--
 * @Author: your name
 * @Date: 2020-06-04 08:54:03
 * @LastEditTime: 2020-09-18 17:03:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\views\production\iview.vue
--> 
<template>
  <div class="iwiew">
    <template v-if="data.t == 1">
      <!-- <template>{{data.c}}</template> -->

      <template v-if="data.title == 1">
        <div :class="data.coarse==1?'bigtitle wit':'bigtitle'" v-if="data.center == 1">{{data.c}}</div>
        <div :class="data.coarse==1?'littletitle wit':'littletitle'" v-else>{{data.c}}</div>
      </template>

      <template v-if="data.isherf == 1">
        <div :class="data.coarse==1?'hyperlink wit':'hyperlink'">
          <a :href="'https://' + data.herfvar">{{data.c}}</a>
        </div>
      </template>

      <template v-if="data.title == 0 && data.title == 0 && data.herfvar == null&& data.center == 0">
        <div  :class="data.coarse==1?'article wit':'article'">{{data.c }}</div>
      </template>


       <template v-if="data.title == 0 && data.title == 0 && data.herfvar == null&& data.center == 1">
        <div :class="data.coarse==1?'articles wit':'articles'">{{data.c }}</div>
      </template>

      
    </template>

    <template v-if="data.t == 2">
      <div class="imgs">
        <img :src="data.picvideo.picUrl" alt />
      </div>
    </template>

    <template v-if="data.t == 3">
      <div class="v">
        <video :src="data.picvideo.videoUrl" muted preload controls></video>
      </div>
    </template>

    <template v-if="data.t == 4">
      <div class="commodity">
        <div class="commoimg">
          <img :src="data.product.pic.picUrl" alt />
        </div>
        <div class="commotext">
          <h1>{{data.product.title}}</h1>
          <!-- <p>的JFK是大家我的JFK是大家赶快福建高考加分高考加分的我的JFK是大家赶快福建高考加分高考加分的我的JFK是大家赶快福建高考加分高考加分的我的JFK是大家赶快福建高考加分高考加分的我</p> -->
          <p>{{data.product.desc}}</p>
          <span class="sp">{{data.product.maxpriceStr}}</span>
          <button @click="tz()">了解更多</button>
        </div>
      </div>
    </template>

    <template v-if="data.t == 5">
      <div class="invitation">
        <div class="images">
          <img :src="data.topic.pic.picUrl" alt />
        </div>
        <div class="doucument">
          <h1>{{data.topic.title}}</h1>
          <!-- <p>{{data.topic.desc}}</p> -->
          <p>是刚好放假啊房价大幅改进和是的干撒电话发吉萨大负荷沙发上的黑暗时代科技孵干撒电话发吉就是的干撒电话发吉萨大负荷沙发上的黑暗时代科技孵化</p>
          <button @click="upinfo(data.topic.id)">了解更多</button>
        </div>
      </div>
    </template>

    <template v-if="data.t == 6">
      <div
        :class="data.t == 1 && data.center == 0 && data.isherf == 1 && data.herfvar? 'hyperlink':''"
        v-if="datas.isherf == 1 "
      >
        <a :href="'https://' + data.herfvar">{{data.c}}</a>
      </div>
    </template>

    <template v-if="data.t == 7">视频帖子</template>
    <template v-if="data.t == 8">长图</template>
    <template v-if="data.t == 9">小程序</template>
  </div>
</template>

<script>
export default {
  name: "iview",
  props: ["data"],
  data() {
    return {
      datas: this.data
    };
  },
  methods: {
    tz() {
       let { href } = this.$router.resolve({
          path: "/productInfo.html",
          query: {
          id: this.data.product.id
        }
      });
      window.open(href, '_blank');
    },
    upinfo(id){
       let { href } = this.$router.resolve({
          path:'/postdetails.html',
          query:{
           id:id
         }
      });
      window.open(href, '_blank');
    },
  }
};
</script>

<style lang="less" scoped>
.wit{
  font-weight: bold;
}
.wit a{
  font-weight: bold;
}
img{
  margin: 30px 0;
}
.bigtitle {
  font-size: 26px; /*no*/
  min-width: 750px;
  text-align: center;
  line-height: 4vw; /* no */
  font-weight: 500; /* no */
}
.littletitle {
  font-size: 20px; /*no*/
  min-width: 750px;
  text-align: left;
  width: 85%;
  margin: 0 auto;
  line-height: 3vw; /* no */
  font-weight: 500;
}
.articles{
  white-space: pre-line;
  text-align: center;
}
.article {
  width: 85%;
  min-width: 750px;
  line-height: 28px; /* no */
  margin: 30px auto;
  font-size: 16px; /* no */
  
}
.hyperlink {
  width: 85%;
  min-width: 750px;
  margin: 30px auto;
  line-height: 28px; /* no */
  color: #000;
  a {
    color: #000;
    font-size: 16px; /* no */
  }
}
.v {
  width: 85%;
  margin: 0 auto;
}
video {
  display: inline-block;
  width: 100%;
  margin: 0 atuo;
}
.imgs {
  width: 85%;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.commodity {
  width: 85%;
  height: 450px;
  background: #f5f5f5;
  margin: 40px auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  .commoimg {
    width: 32%;
    height: 70%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
  p {
    width: 100%;
    height: 80px;
    line-height: 38px;
    text-align: left;
    font-size: 0.7vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
  }
  button {
    width: 220px;
    height: 50px;
    background: #f5f5f5;
    border: none;
    outline: none;
    border-radius: 40px;
    color: #921d22;
    border: 1px solid #921d22;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.invitation {
  width: 85%; /* no */
  height: 450px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 40px auto;
  .images {
    width: 32%;
    height: 70%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}
.sp {
  color: #921d22;
  font-size: 18px; /* no */
  line-height: 110px;
}
.doucument {
  width: 60%;
  height: 70%;
  position: relative;
  h1 {
    font-size: 38px;
    font-weight: 400;
    line-height: 80px;
    height: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p {
    height: 150px;
    width: 100%;
    font-size: 18px;
    letter-spacing: 4px;
    color: #333;
    line-height: 30px;
  }
  button {
    width: 220px;
    height: 50px;
    background: #f5f5f5;
    border: none;
    outline: none;
    border-radius: 40px;
    color: #921d22;
    border: 1px solid #921d22;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.commotext {
  width: 60%;
  height: 70%;
  position: relative;
  h1 {
    font-size: 38px;
    font-weight: 400;
    line-height: 80px;
    height: 80px;
  }
  p {
    height: 155px;
    width: 100%;
    font-size: 18px;
    letter-spacing: 4px;
    color: #333;
    line-height: 30px;
  }
}
@media screen and(max-width:1700px) {
  .iwiew .invitation {
    .doucument {
      h1 {
        font-size: 36px; /* no */
      }
      p {
        font-size: 16px; /* no */
        line-height: 30px;
      }
      button {
        font-size: 16px; /* no */
      }
    }
    .images {
      width: 289px; /* no */
      height: 289px; /* no */
    }
  }
  .commodity .commoimg {
    width: 289px; /* no */
    height: 289px; /* no */
  }
  .commodity .commotext {
    h1 {
      font-size: 36px; /* no */
    }
    p {
      font-size: 16px; /* no */
      line-height: 30px;
    }
    button {
      font-size: 16px; /* no */
    }
  }
  .iwiew .article {
    line-height: 26px; /* no */
  }
}

@media screen and(max-width:1500px) {
  .iwiew .invitation {
    .doucument {
      h1 {
        font-size: 36px; /* no */
      }
      p {
        font-size: 16px; /* no */
        line-height: 30px; /* no */
      }
      button {
        font-size: 16px; /* no */
      }
    }
    .images {
      width: 269px; /* no */
      height: 269px; /* no */
    }
  }
  .commodity .commoimg {
    width: 269px; /* no */
    height: 269px; /* no */
  }
  .commodity .commotext {
    h1 {
      font-size: 36px; /* no */
    }
    p {
      font-size: 16px; /* no */
      line-height: 30px; /* no */
    }
    button {
      font-size: 16px; /* no */
    }
  }
}

@media screen and(max-width:1300px) {
  .iwiew .invitation {
    .doucument {
      h1 {
        font-size: 36px; /* no */
      }
      p {
        font-size: 16px; /* no */
        line-height: 30px; /* no */
      }
      button {
        font-size: 16px; /* no */
      }
    }
    .images {
      width: 229px; /* no */
      height: 229px; /* no */
    }
  }
  .commodity .commoimg {
    width: 229px; /* no */
    height: 229px; /* no */
  }
  .commodity .commotext {
    h1 {
      font-size: 36px; /* no */
    }
    p {
      font-size: 16px; /* no */
      line-height: 30px; /* no */
    }
    button {
      font-size: 16px; /* no */
    }
  }
}

@media screen and(max-width:1100px) {
  .iwiew .invitation {
    .doucument {
      h1 {
        font-size: 34px; /* no */
      }
      p {
        font-size: 16px; /* no */
        line-height: 28px; /* no */
      }
      button {
        font-size: 16px; /* no */
      }
    }
    .images {
      width: 215px; /* no */
      height: 215px; /* no */
    }
  }
  .commodity .commoimg {
    width: 215px; /* no */
    height: 215px; /* no */
  }
  .commodity .commotext {
    h1 {
      font-size: 30px; /* no */
      line-height: 30px;
    }
    p {
      font-size: 16px; /* no */
      line-height: 28px; /* no */
    }
    button {
      font-size: 16px; /* no */
    }
  }
}
</style>